import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
// import router from 'Router';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import workflowApolloClient from '@/lib/appsync/workflow';
import reportApolloClient from '@/lib/appsync/reportApolloClient';
import getJobsCount from '@/graphql/report/queries/getJobsCount.graphql';
import postTLReport from '@/graphql/report/mutations/postTLReport.graphql';
import deleteTLReport from '@/graphql/report/mutations/deleteTLReport.graphql';
import getTLReport from '@/graphql/report/queries/getTLReports.graphql';
import getActionTallies from '@/graphql/report/queries/getActionTallies.graphql';
import getJobCompletionPerc from '@/graphql/report/queries/getJobCompletionPerc.graphql';
import getDefermentByReason from '@/graphql/deferment/queries/getDefermentByReason.graphql';
import { DateTime } from 'luxon';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';
import defermentApolloClient from '@/lib/appsync/deferment';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'reportModule',
  store,
})
class ReportModule extends VuexModule {
  jobCount: any = [];

  actionTallies: any = [];

  jobCompletionPercentage: any = [];

  defermentByReason: any = [];

  @Mutation
  setJobCount(data): void {
    this.jobCount = data;
  }

  @Mutation
  setActionTallies(data): void {
    this.actionTallies = data;
  }

  @Mutation
  setJobCompletionPercentage(data): void {
    this.jobCompletionPercentage = data;
  }

  @Mutation
  setDefermentByReason(data): void {
    this.defermentByReason = data;
  }

  @Action({
    commit: 'setJobCount',
  })
  async getJobCount({ daysAgo = -1 }): Promise<any> {
    const {
      data: {
        get_tl_user_job_count: {
          tally_dict: tallyDict,
        },
      },
    } : any = await reportApolloClient.query({
      query: getJobsCount,
      variables: {
        input: {
          from_time: daysAgo != -1 ? DateTime.utc().minus({ days: daysAgo }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
            : DateTime.utc().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss"),
          to_time: DateTime.utc().toFormat("y-MM-dd'T'HH:mm:ss"),
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
		  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      },
      fetchPolicy: 'network-only',
    });

    const parsedDataset = jsonParse(tallyDict);

     return Object.entries(parsedDataset).map((i: any) => ({
      name: getNameByEmail(i[0]),
      // eslint-disable-next-line camelcase
      jobCount: i[1]?.job_count || 0,
      completed: i[1]?.completed || 0,
    }));
  }

  @Action
  async getTLReport(): Promise<any> {
    const {
      data: {
        get_tl_reports: {
          results,
        },
      },
    } : any = await reportApolloClient.query({
      query: getTLReport,
      variables: {
        input: {
          Username: accountModule.user.email.toLowerCase(),
        },
      },
      fetchPolicy: 'network-only',
    });

    const parsedResults = results.map((i) => JSON.parse(i));
    return parsedResults;
  }

  @Action({
    commit: 'setActionTallies',
  })
  async getActionTallies({ daysAgo = -1}) {
	  if (daysAgo == -1) {
		daysAgo = 1
	  }
	  if (daysAgo == 1) {
		daysAgo = 2
	  }
    const {
      data: {
        get_actioned_tallies: {
          data,
        },
      },
    } : any = await reportApolloClient.query({
      query: getActionTallies,
      variables: {
        input: {
          from_time: daysAgo != -1 ? DateTime.utc().minus({ days: daysAgo }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
            : DateTime.utc().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss"),
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
      fetchPolicy: 'network-only',
    });

    const parsedDataset = jsonParse(data);
    return Object.entries(parsedDataset);
  }

  @Action
  async generateTLReport(report) {
    const isWeekly = report.period === 'Weekly';

    const luxonDate = DateTime.fromISO(report.time.slice(0, -1));
    const offsetDate = DateTime.fromISO(report.time);

    const dayValues = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // const routePath = router.resolve({ name: 'Report' }, {
    //   params: {
    //     ...isWeekly && {
    //       daysAgo: 7,
    //     },
    //   },
    // }).href;

    const listTitle = `${isWeekly ? `Every ${report.day}` : 'Daily'} at ${offsetDate.toFormat('h:mm a')}`;

    let CronSchedule = '';

    if (isWeekly) {
      CronSchedule = `${luxonDate.minute} ${luxonDate.hour} * * ${dayValues.indexOf(report.day)}`;
    } else {
      CronSchedule = `${luxonDate.minute} ${luxonDate.hour} * * *`;
    }
    await reportApolloClient.mutate({
      mutation: postTLReport,
      variables: {
        input: {
          CronSchedule,
          Recipients: [accountModule.user.email],
          ReportTitle: `Tasqinc - Team Lead Report. ${listTitle}`,
          ReportURL: `${window.location.origin}/report`,
          Username: accountModule.user.email.toLowerCase(),
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }

  @Action
  async deleteTLReport(reportId) {
    await reportApolloClient.mutate({
      mutation: deleteTLReport,
      variables: {
        input: {
          TLReportID: reportId,
        },
      },
    });
  }

  @Action({
    commit: 'setJobCompletionPercentage',
  })
  async getJobCompletionPercentage({ daysAgo = -1}) {
    const {
      data: {
        get_job_completion_percentage_by_period: {
          results,
        },
      },
    } : any = await workflowApolloClient.query({
      query: getJobCompletionPerc,
      variables: {
        input: {
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
          period: 'DAILY',
          from_datetime: daysAgo != -1 ? DateTime.utc().minus({ days: daysAgo }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
            : DateTime.utc().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss"),
          to_datetime: DateTime.utc().toFormat("y-MM-dd'T'HH:mm:ss"),
        },
      },
      fetchPolicy: 'network-only',
    });

    const parsedResults = results.map((i) => jsonParse(i));

    return {
      labels: parsedResults.map((i) => i.time),
      data: parsedResults.map((i) => Number(i.percent_complete * 100)),
    };
  }

  @Action({
    commit: 'setDefermentByReason',
  })
  async getDefermentByReason({ daysAgo = -1}) {
    const {
      data: {
        get_deferment_by_reason: {
          dataset,
        },
      },
    } : any = await defermentApolloClient.query({
      query: getDefermentByReason,
      variables: {
        from_date: daysAgo != -1 ? DateTime.utc().minus({ days: daysAgo }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
          : DateTime.utc().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss"),
        to_date: DateTime.utc().toFormat("y-MM-dd'T'HH:mm:ss"),
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
      },
      fetchPolicy: 'network-only',
    });

    const parsedDataset = JSON.parse(dataset);
    const time = parsedDataset.map((i) => i.time);
    const deferment = parsedDataset.map((i) => i.Deferment);
	var reasons = []
	if (parsedDataset.length > 0) {
		reasons = parsedDataset[0].Reason;
	}
    

    return {
      time,
      datasets: reasons.map((i, idx) => ({
        label: '',
        backgroundColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#4BDF95', '#303279', '#FFD000'][idx],
        borderColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#4BDF95', '#303279', '#FFD000'][idx],
        borderWidth: 1,
        data: time.map((t, tidx) => deferment[tidx][idx]),
      })),
    };
  }
}

export default getModule(ReportModule);
