import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import notificationApolloClient from '@/lib/appsync/notification';
import updateNotificationSettings from '@/graphql/userSettings/mutations/updateNotificationSettings.graphql';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'notificationsModule',
  store,
})
class UserProfileModule extends VuexModule {
  @Action
  async updateNotificationSettings({
    notify_on_new_job,
    notify_on_reassigned_tasq,
    username,
  }) {
    try {
      const {
        data: {
          update_push_settings: {
          },
        },
      } : any = await notificationApolloClient.mutate({
        mutation: updateNotificationSettings,
        variables: {
          input: {
			  event_types_array: [
				  {
					  event_type: 'notify_on_new_job',
					  value: notify_on_new_job,
				  },
				  {
                event_type: 'notify_on_reassigned_tasq',
                value: notify_on_reassigned_tasq,
              },
			  ],
			  username,
          },
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }
}

export default getModule(UserProfileModule);

// https://5tzxpypam5ezzj6xzmj3lfq7pa.appsync-api.us-east-2.amazonaws.com/graphql
