
import { Component, Vue } from 'vue-property-decorator';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import userPreferenceDB from '@/lib/userPreference';
import { getComponent, sleep } from '@/utils/helpers';
import userProfileModule from '@/store/modules/userProfileModule';
import notificationsModule from '@/store/modules/notificationsModule';
import accountModule from '@/store/modules/accountModule';
import reportModule from '@/store/modules/reportModule';
import { getNameByEmail } from '@/utils/users';
import workflowModule from '@/store/modules/workflowModule';
import Multiselect from 'vue-multiselect';
import parsePhoneNumber from 'libphonenumber-js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { clearSession } from '@/lib/auth';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Dropdown: () => getComponent('common/Dropdown'),
    Datetime,
    VuePhoneNumberInput,
    Multiselect,
  },
})
export default class UserProfile extends Vue {
  savingProfile = false;

  savingReport = false;

  sendingInvite = false;

  windowWidth = window.innerWidth;

  firstName = '';

  lastName = '';

  userCountryCode: any = 'US';

  userNationalNumber: any = '';

  userFullFormatNumber: any = {};

  userRole = 'Engineers';

  userDefaultFilter = '';

  userDefaultPage = '';

  team: any = [];

  email = '';

  phoneNumber = '';

  signOutPopup = false;

  userInviteEmail = '';

  saveSuccessfulNotificationTitle = 'Changes saved';

  saveSuccessfulNotificationSubTitle = 'You may navigate away from this page.';

  acceptingTasqs = true;

  showSaveSuccessfulNotification = false;

  notifyOnNewJob = true;

  notifyOnReassignedTasq = true;

  loadingProfilePage = false;

  username = '';

  userInitial = '';

  dropdownLocation: any[] = [];

  report = {
    period: 'Daily',
    day: '',
    time: new Date(new Date().setHours(0, 0, 0)).toISOString(),
  };

  filteringDetails: any = {
    typesOptions: [],
    selectedUsernames: [],
    selectedRoutes: [],
    selectedTeams: [],
    selectedAreas: [],
    selectedWaitingOn: [],
    cycleTimeGreaterThanFilter: null,
    cycleTimeLessThanFilter: null,
    unitsGreaterThanFilter: null,
    unitsLessThanFilter: null,
    defermentPercentGreaterThanFilter: null,
    defermentPercentLessThanFilter: null,
  };

  isOnlineApp = navigator.onLine;

  reports: any = [];

  didToggleAcceptingTasqs() {
    this.updateAcceptingTasqs();
  }

  get getCurrentName(): string {
    return getNameByEmail(workflowModule.user.email);
  }

  get teams() {
    const structuredTeams: any = [];
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < workflowModule.teams.length; x++) {
      structuredTeams.push(workflowModule.teams[x]);
    }
    return structuredTeams;
  }

  get disableGeneratePDF() {
    return !!(this.report.period === 'Weekly' && this.report.day === '');
  }

  didSelectDropdown(e) {
    const rect = e.srcElement.parentElement.getBoundingClientRect();
    this.dropdownLocation = [rect.x, rect.y + 80];
  }

  hideDropdown() {
    this.dropdownLocation = [];
  }

  resetButtonTapped() {
    this.firstName = workflowModule.user.firstName;
    this.lastName = workflowModule.user.lastName;
    this.email = workflowModule.user.email;
    this.phoneNumber = workflowModule.user.phoneNumber;
    this.username = workflowModule.user.username.toLowerCase();
    this.userInitial = workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0);
    this.userRole = workflowModule.user.role;
    this.userDefaultPage = workflowModule.user.defaultPage;
    this.userDefaultFilter = workflowModule.user.defaultUserFilter;
    this.userCountryCode = parsePhoneNumber(this.phoneNumber)?.country;
    this.userNationalNumber = parsePhoneNumber(this.phoneNumber)?.nationalNumber;
  }

  onResize() {
    this.windowWidth = window.innerWidth;
  }

  get isMobile() {
    return this.windowWidth < 768;
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('online', this.onlineMode);
    window.removeEventListener('offline', this.offlineMode);
  }

  async onlineMode() {
    this.isOnlineApp = true;
  }

  async offlineMode() {
    this.isOnlineApp = false;
  }

  logout() {
    clearSession();
    this.$router.push({ name: 'Login' });
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    window.addEventListener('online', this.onlineMode);
    window.addEventListener('offline', this.offlineMode);
  }

  settings = [
    {
      text: 'New Tasq is assigned to me',
      value: 'NEW_TASQ_ASSIGNED_TO_ME',
      checked: true,
    },
    { text: 'Tasq is reassigned to me', value: 'TASQ_REASSIGNED_TO_ME', checked: true },
    {
      text: 'Off-Target Tasq assigned to me returns to target',
      value: 'OFF_TARGET_TASQ_ASSIGNED_TO_ME_RETURN_TO_NORMAL',
      checked: true,
    }, {
      text: 'Todo item assigned to me',
      value: 'NEW_TODO_ASSIGNED_TO_ME',
      checked: true,
    },
  ];

  workStartHour: any = null

  workEndHour: any = null

  notificationSettings: any = []

  async saveNotificationSettings() {
    await workflowModule.postUserNotificationSettings({
      payload: this.settings.filter((s) => s.checked).map((v) => v.value),
      workStartHour: this.workStartHour,
      workEndHour: this.workEndHour,
    });
  }

  setSettingsCheckValues() {
    this.settings = this.settings.map((item) => {
       const setting = item;
      if (this.notificationSettings.includes(setting.value)) {
        // @ts-ignore
        setting.checked = true;
      } else {
         // @ts-ignore
        setting.checked = false;
      }
      return setting;
    });
  }

  async created() {
    this.loadingProfilePage = true;
    await sleep(1000);
    await workflowModule.listTeams({ useExisting: true });
    await workflowModule.getUserProfile({
      username: accountModule.user.email.toLowerCase(),
    });

    const settings = (workflowModule.user.notification_settings);

    if (settings) {
      if (settings.AllowedEvents) {
        this.notificationSettings = settings.AllowedEvents;
        if (this.notificationSettings) {
          this.setSettingsCheckValues();
        }
        this.workStartHour = settings.WorkHourStartTime;
        this.workEndHour = settings.WorkHourEndTime;
      }
    }

    this.loadingProfilePage = false;
    this.firstName = workflowModule.user.firstName;
    this.lastName = workflowModule.user.lastName;
    this.email = workflowModule.user.email;
    this.phoneNumber = workflowModule.user.phoneNumber;
    this.username = workflowModule.user.username.toLowerCase();
    this.userInitial = workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0);
    this.userRole = workflowModule.user.role;
    this.userDefaultPage = workflowModule.user.defaultPage;
    this.userDefaultFilter = workflowModule.user.defaultUserFilter
      && ['Assigned to me', 'Assigned to my team', 'Custom'].includes(
        workflowModule.user.defaultUserFilter,
      )
        ? workflowModule.user.defaultUserFilter
        : 'Assigned to me';
    this.userCountryCode = parsePhoneNumber(this.phoneNumber)?.country;
    this.userNationalNumber = parsePhoneNumber(this.phoneNumber)?.nationalNumber;
    this.team = workflowModule.user.team ? [workflowModule.user.team] : [];

    const defaultFilteringSettings: any = workflowModule.user.defaultFilteringDetails;
    if (this.userDefaultFilter === 'Custom' && defaultFilteringSettings) {
      if (
        defaultFilteringSettings
        && Object.keys(defaultFilteringSettings)
        && Object.keys(defaultFilteringSettings).length
      ) {
        this.filteringDetails = JSON.parse(JSON.stringify(defaultFilteringSettings));
      }
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sendInvite() {
    this.sendingInvite = true;
    await userProfileModule
      .sendInvitationEmail({
        user_email: accountModule.user.email,
        recipient_email: this.userInviteEmail,
        user_first_name: this.userInviteEmail,
      })
      .then(() => {
        this.saveSuccessfulNotificationTitle = 'Invitation sent';
        this.showSaveSuccessfulNotification = true;
        this.userInviteEmail = '';
        this.sleep(4000).then(() => {
          this.showSaveSuccessfulNotification = false;
        });
      });
    this.sendingInvite = false;
  }

  async updateNotificationSettings() {
    await notificationsModule
      .updateNotificationSettings({
        username: accountModule.user.email.toLowerCase(),
        notify_on_new_job: this.notifyOnNewJob,
        notify_on_reassigned_tasq: this.notifyOnReassignedTasq,
      })
      .then(() => {
        this.saveSuccessfulNotificationTitle = 'Changes saved';
        this.showSaveSuccessfulNotification = true;
        this.sleep(4000).then(() => {
          this.showSaveSuccessfulNotification = false;
        });
      });
  }

  openReportTab() {
    window.open(`${window.location.origin}/report/7`);
  }

  async updateAcceptingTasqs() {
    await userProfileModule
      .updateAcceptingTasqs({
        username: accountModule.user.email.toLowerCase(),
        accepting_tasqs: this.acceptingTasqs,
      })
      .then(() => {
        this.saveSuccessfulNotificationTitle = 'Changes saved';
        this.saveSuccessfulNotificationSubTitle = 'You may navigate away from this page.';
        this.showSaveSuccessfulNotification = true;
        this.sleep(4000).then(() => {
          this.showSaveSuccessfulNotification = false;
        });
      });
  }

  async saveTLReport() {
    this.savingReport = true;
    await reportModule.generateTLReport(this.report).then(async () => {
      this.saveSuccessfulNotificationTitle = 'Changes saved';
      this.savingProfile = false;
      this.showSaveSuccessfulNotification = true;
      this.sleep(4000).then(() => {
        this.showSaveSuccessfulNotification = false;
      });
      this.reports = await reportModule.getTLReport();
      this.savingReport = false;
    });
  }

  async deleteTLReport({ TLReportID }) {
    await reportModule.deleteTLReport(TLReportID).then(async () => {
      this.saveSuccessfulNotificationTitle = 'Changes saved';
      this.savingProfile = false;
      this.showSaveSuccessfulNotification = true;
      this.sleep(4000).then(() => {
        this.showSaveSuccessfulNotification = false;
      });
      this.reports = await reportModule.getTLReport();
    });
  }

  async saveProfile() {
    try {
      this.savingProfile = true;
      const localTeam: any[] = [];
      // eslint-disable-next-line no-plusplus
      if (this.team && this.team.length) {
        for (let x = 0; x < this.team.length; x++) {
          localTeam.push(this.team[x].value);
        }
      }

      if (this.userFullFormatNumber) {
        this.phoneNumber = this.userFullFormatNumber.formattedNumber;
      }

      if (this.userDefaultFilter === 'Custom' && this.filteringDetails) {
        await userPreferenceDB.setItem('user-tasq-page-filtering-settings', this.filteringDetails);
      }

      if (this.isMobile) {
          this.saveNotificationSettings();
      }

      await userProfileModule
        .updateUserProfile({
          username: accountModule.user.email.toLowerCase(),
          accepting_tasqs: true,
          first_name: this.firstName,
          last_name: this.lastName,
          team: this.team,
          phone_number: this.phoneNumber,
          roles: [this.userRole],
          default_page: this.userDefaultPage,
          default_user_filter: this.userDefaultFilter,
          custom_profile_json: JSON.stringify(this.filteringDetails),
          user_email: accountModule.user.email.toLowerCase(),
        })
        .then(async () => {
          this.saveSuccessfulNotificationSubTitle = 'You may navigate away from this page.';
          this.saveSuccessfulNotificationTitle = 'Changes saved';

          await workflowModule.getUserProfile({
            username: accountModule.user.email.toLowerCase(),
            useExisting: false,
          });

          this.showSaveSuccessfulNotification = true;
          await accountModule.getReassignmentList({ useExisting: false });
          this.savingProfile = false;
          this.sleep(4000).then(() => {
            this.showSaveSuccessfulNotification = false;
          });
        });
    } catch (error: any) {
      this.savingProfile = false;
      this.saveSuccessfulNotificationSubTitle = 'Something went wrong please try again.';
      this.saveSuccessfulNotificationTitle = 'Unable to save changes';
      this.savingProfile = false;
      this.showSaveSuccessfulNotification = true;
      this.sleep(4000).then(() => {
        this.showSaveSuccessfulNotification = false;
      });
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
  }

  didSelectDropdownOption() {
    this.dropdownLocation = [];
  }
}
